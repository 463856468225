@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";
@import "@vahak/core/dist/scss/mixins";

.main {
    display: grid;
    align-items: stretch;
    grid-auto-columns: minmax(auto, 300px);

    grid-auto-flow: column;
    gap: 10px;
    width: 100%;
    overflow: auto;
    @include hideScrollbar;
}
