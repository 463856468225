@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.main {
    width: 343px;
    border-radius: 16px;
    box-shadow: 0 2px 5px -1px rgba(50, 50, 93, 0.25), 0 1px 3px -1px rgba(0, 0, 0, 0.3);
    position: relative;

    display: grid;
    grid-template-rows: max-content max-content auto;

    .headerContent {
        margin-top: 10px;
        height: 34px;
        .actionBtnWrapper {
            margin-right: 14px;
            margin-left: auto;

            .adminOptionDropdown {
                left: -140px;
            }

            .shareBtn {
                display: flex;
                span {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    .bodyContent {
        padding: 0 12px;
    }

    .footerContent {
        display: grid;
        grid-template-rows: auto min-content;
        div {
            &:last-child {
                border-bottom-left-radius: 16px;
                border-bottom-right-radius: 16px;
            }
        }

        &.singleChild {
            min-height: 92px;
            max-height: 92px;
            height: 100%;
        }
    }
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .main {
        &.mobileResponsive {
            width: 100%;
        }
    }
}
