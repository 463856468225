@import "@vahak/core/dist/scss/variables";

.main {
    padding: 30px 55px;
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .main {
        padding: 20px;
    }
}
