@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.layout {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
        "header"
        "main"
        "footer";

    height: 100dvh;
    position: fixed;
    inset: 0;
    .header {
        grid-area: header;
    }
    .footer {
        grid-area: footer;
    }
    .main {
        z-index: 0;
        grid-area: main;
        overflow-y: auto;
    }

    .header,
    .footer {
        padding: 20px;
        display: grid;
        grid-template-columns: max-content auto;
        column-gap: 20px;
        background-color: inherit;
        z-index: 1;
    }
}

.main {
    padding: 20px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    .locations {
        display: grid;
        grid-template-rows: auto auto;
        gap: 15px;

        .location {
            display: grid;
            grid-template-columns: 20px auto;
            align-items: center;
            column-gap: 12px;
            position: relative;

            & > svg {
                height: 100%;
                min-width: 100%;
            }

            &::after {
                content: "";
                display: inline-block;
                height: 34px;
                width: 0;

                position: absolute;
                inset: 0;
                left: 10px;
                top: 5px;
                transform: translate(0%, 100%);
                border-right: 2px solid $grey-500;
            }
            &:nth-last-of-type(1) {
                &::after {
                    display: none !important;
                }
            }
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        .item {
            display: grid;
            grid-template-columns: 50px auto;
            align-items: start;
            padding: 4px 0;

            .desc {
                height: 100%;
                border-bottom: 1px solid $grey-200;
                padding-bottom: 12px;
            }
        }
    }

    .lorryTypeSelection {
        margin-top: 20px;

        display: grid;
        grid-template-columns: repeat(2, auto);
        gap: 12px;
    }
}

.quickAction {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    margin-top: 20px;
}
