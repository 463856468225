@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.main {
    height: 100%;
}

.loader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.addNewBtnContainer {
    display: flex;
}

.sheet {
    padding-top: 15px;
}

.addNewBtn {
    background-color: #fff !important;
    color: #2355fc !important;
    border: solid 1px #2355fc !important;
    position: static !important;
    border-radius: 4px !important;
    font-family: var(--font-medium);
    box-shadow: none !important;
    svg {
        margin-right: 12px;
    }
}

.selectSaveLods {
    h3 {
        color: #666666;
        background-color: white;
        font-family: var(--font-semiBold);
        margin-bottom: 15px;
        position: sticky;
        top: 0;
        z-index: 1;
    }
}

.backSaveLoads {
    color: #333333;
    font-family: var(--font-medium);
    padding-left: 22px;
    position: relative;
    cursor: pointer;
    &::before {
        content: "";
        width: 10px;
        height: 10px;
        border: solid 1px #333333;
        transform: rotate(-45deg);
        position: absolute;
        left: 3px;
        top: 7px;
        border-right: none;
        border-bottom: none;
    }
}

.assuredBadge {
    position: absolute;
    right: 10px;
    top: 10px;
    background-color: #ff4a2e;
    padding: 4px 8px;
    border-radius: 1em;
    color: $white;
    font-size: 12px;
}
.disableCard {
    pointer-events: none;
    cursor: not-allowed;
    filter: grayscale(1);
}

.noRecordFoundWrapper {
    padding: 80px;
}

@media (max-width: $mobileBreakpointMaxWidth) {
    .main {
        // margin-top: -30px;
    }

    .noRecordFoundWrapper {
        padding: 20px;
    }

    .addNewBtnContainer {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        h3 {
            margin-bottom: 0;
        }
    }
    .assuredBadge {
        right: 12px;
        top: 0px;
        transform: translateY(-50%);
    }

    .addNewBtn {
        width: 180px !important;
        height: 43px !important;
        font-size: calcRem(14) !important;
        // margin: auto !important; Commented to match new UI
        margin: 20px 0;
        left: 0 !important;
        right: 0 !important;
        display: block;
        svg {
            width: 14px;
            height: 14px;
        }
    }
    .sheet {
        padding-top: 20px;
    }

    .selectSaveLods {
        // max-height: 400px;
        padding-bottom: 140px;
        overflow: auto;
        h3 {
            font-size: calcRem(14);
        }

        .saveloadCard,
        .expiredLoadCard {
            padding: 15px 13px;
            background-color: #ffffff;
            border: solid 2px #c4c4c4;
            border-radius: 5px;
            position: relative;
            margin-bottom: 15px;
            cursor: pointer;

            .dateTime {
                color: #c1c1c1;
                font-size: calcRem(11);
                background-color: #fff;
                font-family: var(--font-medium);
            }
            .textBox {
                position: relative;
                padding-left: 33px;
                .dot {
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    border: solid 2px #cbcbcb;
                    position: absolute;
                    left: 0;
                    top: 1px;
                }
            }

            .cityWrap {
                display: flex;
                flex-direction: column;
                position: relative;
                padding-left: 12px;
                h4 {
                    color: #333333;
                    font-size: calcRem(12);
                    font-family: var(--font-medium);
                    line-height: calcRem(16);
                    margin-bottom: 12px;
                }
                .col {
                    position: relative;
                    &:nth-child(1) {
                        &::before {
                            content: "";
                            width: 7px;
                            height: 7px;
                            border-radius: 50%;
                            background-color: #000000;
                            position: absolute;
                            left: -12px;
                            top: 5px;
                        }
                    }
                    &:nth-child(2) {
                        h4 {
                            margin-bottom: 0;
                        }
                        &::before {
                            content: "";
                            width: 7px;
                            height: 7px;
                            background-color: #000000;
                            position: absolute;
                            left: -12px;
                            top: 5px;
                        }
                    }
                }

                &::before {
                    content: "";
                    border-left: 1px solid #999999;
                    height: 59%;
                    position: absolute;
                    top: 8px;
                    bottom: 10px;
                    left: 3px;
                    margin: auto 0;
                }
            }
            .materialQnty {
                h5 {
                    color: #000000;
                    font-size: calcRem(12);
                    font-family: var(--font-medium);
                    line-height: calcRem(16);
                    margin-bottom: 8px;
                }
            }
        }

        .isSelected {
            border: solid 2px #2355fc;
            .dateTime {
                color: #333333;
            }
            .textBox {
                .dot {
                    border: solid 2px #2355fc;
                    &::after {
                        content: "";
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: #2355fc;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }
            }
        }
    }

    .backSaveLoads {
        font-size: calcRem(14);
        margin-bottom: 40px;
        padding-left: 16px !important;
        &::before {
            top: 5px !important;
        }
    }
}

@media (min-width: $desktopBreakpointMinWidth) {
    .main {
        // margin-top: 40px;
    }
    .addNewBtnContainer {
        justify-content: space-between;
        align-items: center;
    }
    .addNewBtn {
        width: 222px !important;
        height: 50px !important;
        font-size: calcRem(16) !important;
        svg {
            width: 16px;
            height: 16px;
        }
    }

    .selectSaveLods {
        margin-top: 15px;
        max-height: 330px;
        overflow: auto;
        min-height: 330px;
        h3 {
            font-size: calcRem(16);
            padding-bottom: 15px;
            margin-bottom: 0;
        }
        // .availableLoadsListWrapper {
        //     height: 300px;
        //     overflow: auto;
        // }
        .saveloadCard,
        .expiredLoadCard {
            padding: 15px 13px;
            background-color: #ffffff;
            border-radius: 4px;
            border: solid 2px #c4c4c4;
            position: relative;
            margin-bottom: 15px;
            cursor: pointer;
            .dateTime {
                color: #c1c1c1;
                font-size: calcRem(12);
                background-color: #fff;
                padding: 0 3px;
                position: absolute;
                top: -10px;
                right: 15px;
                font-family: var(--font-medium);
            }
            .textBox {
                position: relative;
                padding-left: 33px;
                .dot {
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    border: solid 2px #cbcbcb;
                    position: absolute;
                    left: 0;
                    top: 1px;
                }
            }

            .cityWrap {
                display: flex;
                flex-direction: row;
                .col {
                    &:nth-child(1) {
                        padding-right: 15px;
                    }
                    &:nth-child(2) {
                        position: relative;
                        padding-left: 15px;
                        svg {
                            height: 7px;
                            width: 7px;
                            position: absolute;
                            left: -3px;
                            top: -7px;
                            bottom: 0;
                            margin: auto 0;
                        }
                    }
                }
                h4 {
                    color: #333333;
                    font-size: calcRem(14);
                    font-family: var(--font-medium);
                    line-height: calcRem(21);
                }
            }
            .materialQnty {
                h5 {
                    color: #666666;
                    font-size: calcRem(14);
                    font-family: var(--font-medium);
                    line-height: calcRem(21);
                    margin: 0;
                }
            }
        }

        .isSelected {
            border: solid 2px #2355fc;
            .dateTime {
                color: #333333;
            }
            .textBox {
                .dot {
                    border: solid 2px #2355fc;
                    &::after {
                        content: "";
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: #2355fc;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }
            }
        }
    }

    .backSaveLoads {
        margin-bottom: 50px;

        font-size: calcRem(16);
        // margin-bottom: 10px;
        // margin-bottom: 40px;
        position: relative;
        // top: -45px;
    }
}
