@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.main {
    background-color: #ededed;
    padding: 8px 12px;
    height: 100%;

    .container {
        height: 100%;
    }

    .bidNowBtn {
        button {
            height: 40px;
        }
    }
}

//Desktop Screen only
@media (min-width: $desktopBreakpoint) {
}

// Mid screen
@media (min-width: $mobileBreakpoint) and (max-width: $desktopBreakpoint) {
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
}
