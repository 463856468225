@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";
@import "@vahak/core/dist/scss/mixins";

// Toggler button
.dropDownToggler {
    margin-top: 10px;
}

// Lorry List for user
.dropDownListWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 10px;
    .dropDownList {
        height: 250px !important;
        width: 420px;
        padding-right: 10px;
        overflow-y: auto;
        @include hideScrollbar;

        .noItemList {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
        }
    }
}

.main {
    display: flex;
    align-items: center;
    gap: 12px;
    [data-filter-label] {
        min-width: fit-content;
    }
    .list {
        display: inline-flex;
        overflow-x: auto;
        padding: 15px 0;
        gap: 12px;
        @include hideScrollbar;
    }
    .chip {
        width: 124px;
        height: 72px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 4px !important;
        .row {
            display: inline-flex;
            gap: 4px;
        }
        position: relative;
        .remove {
            position: absolute;
            top: 0px;
            right: 0px;
            transform: translate(50%, -50%);

            width: 20px;
            height: 20px;

            svg {
                height: 100%;
                max-width: 100%;
                border: 50%;
            }
        }
    }
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .list {
        padding: 0;
        width: 100%;
    }

    .dropDownListWrapper {
        padding: 0;
        gap: 25px;

        .dropDownList {
            height: 400px !important;
            width: 100%;
            padding-right: 0px;
            .noItemList {
                height: 400px;
            }
        }
    }
}
