@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.ServiceTypeModalBodyWrapper {
    padding: 0 10px;

    .headingWrapper {
    }

    .serviceTypeWrapper {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-top: 30px;
        .serviceTypeCard {
            display: flex;
            align-items: center;
            gap: 10px;
            border: 2px solid #e3e3e3;
            padding: 10px 20px;
            min-width: 400px;
            cursor: pointer;
            border-radius: 5px;

            &.active,
            &:hover {
                border: 2px solid #2355fc;
                color: #2355fc;
                background-color: #e9f5ff;
            }
        }
    }
}

//Desktop Screen only
@media (min-width: $desktopBreakpoint) {
}

// Mid screen
@media (min-width: $mobileBreakpoint) and (max-width: $desktopBreakpoint) {
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .ServiceTypeModalBodyWrapper {
        padding: 10px;
        margin-right: -20px;
        .serviceTypeWrapper {
            .serviceTypeCard {
                min-width: unset;
            }
        }
    }
}
