@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.formFieldsSection {
    position: relative;
    background-color: #ffffff;
    button {
        font-family: var(--font-semiBold);
    }
}

@media (max-width: $mobileBreakpointMaxWidth) {
    .formNodeWrap {
        height: 100%;
    }
    .formNodeWrap > form {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    .formFieldsSection {
        height: 100%;
        button {
            position: sticky;
            z-index: 2;
            height: 50px;
            min-height: 50px;
            font-size: 1rem;
            // position: fixed;
            margin-bottom: 15px;
            bottom: 5px;
            box-shadow: 0 3px 23.28px 12px rgba(162, 162, 162, 0.47);
        }
    }
}

@media (min-width: $desktopBreakpointMinWidth) {
    .formNodeWrap {
        margin: 0 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        // min-height: 400px;
        overflow: visible;
        > form {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }
    }

    .formFieldsSection {
        flex-basis: 55%;
        display: flex;
        flex-direction: column;
        button {
            font-size: calcRem(18);
        }
    }
}
