@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.main {
    .locationControl {
        border: none;
        background-color: transparent;
        box-shadow: none;
        &:hover {
            border: none;
        }
    }
    .locationInput input {
        font-family: var(--font-semiBold) !important;
        font-size: 16px !important;
        color: $grey-900;
    }

    .locationOption {
        padding: 12px;
        font-family: var(--font-medium);
        color: $grey-900;
        display: grid;
        grid-template-columns: auto auto;
        justify-content: start;
        align-items: center;
        column-gap: 12px;

        .locationOptionIcon {
            width: 40px;
            height: 40px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }
    }
    .locationValue {
        font-family: var(--font-semiBold);
        color: $grey-900;
    }
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .main {
        .placeholder,
        .locationControl {
            font-size: calcRem(14);
        }
    }
}
