@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.main {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1px;

    &:not(.flat) {
        background-color: white;
        border-radius: 15px;
        border: 1px solid #e3e3e3;
        width: 54px;
        height: 24px;
    }
}

//Desktop Screen only
@media (min-width: $desktopBreakpoint) {
}

// Mid screen
@media (min-width: $mobileBreakpoint) and (max-width: $desktopBreakpoint) {
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
}
