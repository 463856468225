@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.lorryCard {
    width: fit-content;
    display: inline-flex;
    border: solid 2px #c4c4c4;
    border-radius: 8px;
    padding: 12px;
    padding-left: 0;
}

.priceForm {
    height: 100%;
    flex-grow: 1;
    margin-bottom: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media (max-width: $mobileBreakpoint) {
    .lorryCard {
        width: 100%;
    }
    .form {
        .formAction {
            position: fixed;
            background-color: white;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 20px;
        }
    }
}
