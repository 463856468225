@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.main {
    margin: 0px;
    &.medium {
        margin-top: 24px;
    }
    &.large {
        margin-top: 32px;
    }
}

@media (-webkit-device-pixel-ratio: 1.5) {
    .main {
        // margin-bottom: 40px;
    }
}
