@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";
@import "@vahak/core/dist/scss/mixins";

.main {
    height: 44px;
    .logoWrapper {
        position: relative;
        cursor: pointer;
        min-width: 54px;

        .logo {
            margin: auto;
        }

        .rating {
            position: absolute;
            bottom: -6px;
        }
    }
    .company {
        display: grid;
        grid-auto-flow: row;
        row-gap: 2px;
        width: 100%;
        min-width: 0;

        .companyDesc {
            display: grid;
            grid-auto-flow: column;
            justify-items: start;
            justify-content: start;
            align-items: center;
            column-gap: 4px;
            min-width: 0;
        }
    }

    [data-service-type] {
        font-size: 11px;
    }
    [data-one-line-text] {
        width: 100%;
        display: block;
        min-width: initial;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

//Desktop Screen only
@media (min-width: $desktopBreakpoint) {
}

// Mid screen
@media (min-width: $mobileBreakpoint) and (max-width: $desktopBreakpoint) {
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
}
