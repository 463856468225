@import "@vahak/core/dist/scss/variables";

.main {
    padding: 20px 55px;

    .questionContainer {
        .ansDescList {
            list-style-type: disc;
        }
    }
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .main {
        padding: 20px;
    }
}
