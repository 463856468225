@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.FieldHeading {
    margin-bottom: 10px;
}

@media (-webkit-device-pixel-ratio: 1.5) {
    .FieldHeading {
        margin-bottom: 40px;
    }
}
