@import "@vahak/core/dist/scss/functions";
@import "@vahak/core/dist/scss/variables";

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainContainer {
    @include flex-center;
    flex-direction: column;
    gap: 15px;

    .textContainer {
        font-family: var(--font-medium);
    }

    .btnContainer {
        .actionBtn {
            min-width: 140px;
            width: 100%;
            height: 42px;
            border-radius: 6px;
        }
    }
}

@media (min-width: $desktopBreakpointMinWidth) {
    .mainContainer {
        .textContainer {
            font-size: calcRem(16);
        }
        .btnContainer {
            .actionBtn {
                font-size: calcRem(14);
            }
        }
    }
}

@media (max-width: $mobileBreakpointMaxWidth) {
    .mainContainer {
        gap: 5px;
        .iconContainer svg {
            width: 90px;
            height: 70px;
        }
        .textContainer {
            font-size: calcRem(14);
        }
        .btnContainer {
            .actionBtn {
                font-size: calcRem(12);
            }
        }
    }
}
