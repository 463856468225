@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.marquee {
    white-space: nowrap;
    overflow: hidden;
    animation: marquee 13s linear infinite;
    width: fit-content;

    span {
        white-space: pre;
    }
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

//Desktop Screen only
@media (min-width: $desktopBreakpoint) {
}

// Mid screen
@media (min-width: $mobileBreakpoint) and (max-width: $desktopBreakpoint) {
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
}
