@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.paddedContainer {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 120px;
}

.main {
    padding: 0px;
    &:not(.isLoggedIn) {
        padding-top: 65px;
        &.embeddedWithProfile {
            padding-top: 0;
        }
    }
    &.companyNotRegistered {
        padding-top: 105px;
    }
    .header {
        background: linear-gradient(92.32deg, #182b63 3.63%, #223d8d 50.3%, #182b63 94.64%);
        height: 320px;
        .aligner {
            @extend .paddedContainer;
            .coverRow {
                height: 260px;
                display: grid;
                grid-template-columns: auto auto;
                justify-content: space-between;
                align-items: center;
                gap: 50px;

                .textBox {
                    max-width: 500px;
                    font-family: var(--font-semiBold);
                    color: white;
                    & h1 {
                        font-size: calcRem(36);
                    }
                }
                .imageBox {
                    display: initial;
                    align-self: flex-end;
                }
            }
        }

        .searchWrapper {
            margin-top: -52px;
            .ctaWrapper {
                display: grid;
                grid-auto-flow: column;
                column-gap: 6px;
                background-color: $grey-150;
                width: min-content;
                border-radius: 16px 16px 0px 0px;
                padding: 4px 4px 0 4px;

                .cta {
                    width: 180px;
                    button {
                        background-color: transparent;
                        border-radius: 13px 13px 0px 0px;
                        width: 100%;
                    }
                    &:not(.active) button {
                        color: $grey-700;
                        svg,
                        svg path {
                            fill: $grey-700;
                        }
                    }
                    &.active button {
                        background-color: $grey-1000;
                    }
                }
            }

            .locationForm {
                display: grid;
                grid-template-columns: auto 200px;
                box-shadow: 0px 3px 20px 0px #0000001a;
                border-radius: 0px 16px 16px 16px;
                padding: 40px 10px;
                background-color: $white;
                column-gap: 12px;
                .locationInputWrapper {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    column-gap: 12px;
                    .locationContainer {
                        padding: 12px 20px;
                        border: 1px solid $grey-900;
                        background-color: $grey-100;
                        border-radius: 32px;
                    }
                }
            }
        }
        .lorrySelection {
            margin-top: 20px;
        }
    }
    .body {
        @extend .paddedContainer;
        padding-top: 250px;
        padding-bottom: 20px;
        .shortDesc {
            color: $grey-700;
            font-size: calcRem(13);
            font-weight: 600;
        }

        .listItems {
            margin: 20px 0;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            gap: 20px;
        }
        .miscContent {
            margin: 20px -55px;
        }
    }
    .noBackground {
        background: none;
        margin-top: 100px;
    }
    .noPaddedBody {
        padding-top: 20px;
    }
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .paddedContainer {
        max-width: unset;
        padding: 0;
        margin: 0;
    }
    .mobilePaddingContainer {
        padding: 0 20px;
    }
    .main {
        padding: 0px;

        &:not(.isLoggedIn) {
            padding-top: 0px;
            margin-top: 55px;
        }
        &.companyNotRegistered {
            margin-top: 95px;
        }

        .header {
            background: none;
            height: auto;
            @extend .mobilePaddingContainer;
            .aligner {
                .coverRow {
                    height: auto;
                    gap: 0px;

                    .imageBox {
                        display: none;
                    }
                    .textBox h1 {
                        font-size: calcRem(16);
                        color: $grey-1000;
                        margin: 0;
                        margin: 20px 0px;
                    }
                }
            }
            .searchWrapper {
                margin-top: 0px;
                gap: 0px;
                .ctaWrapper {
                    width: auto;
                    background-color: transparent;
                    column-gap: 0;
                    grid-template-columns: repeat(2, 1fr);

                    .cta {
                        width: 100% !important;
                        button {
                            height: 44px;
                        }
                        &:not(.active) button {
                            background-color: $grey-200;
                            color: $grey-700;
                            svg,
                            svg path {
                                fill: $grey-700;
                            }
                        }
                        &.active button {
                            background-color: $grey-1000;
                        }
                        &:nth-of-type(1) button {
                            border-radius: 16px 0px 0px 16px;
                        }
                        &:nth-of-type(2) button {
                            border-radius: 0px 16px 16px 0px;
                        }
                    }
                }

                .locationForm {
                    grid-template-columns: none;
                    grid-template-rows: auto auto;
                    background-color: initial;
                    row-gap: 12px;
                    box-shadow: none;
                    padding: 12px 0;
                    .locationInputWrapper {
                        border: 1px solid $grey-300;
                        border-radius: 32px;
                        column-gap: 0;
                        .locationContainer {
                            border: none;
                            background-color: transparent;
                            border-radius: 0;
                            padding: 6px 20px;

                            &:first-of-type {
                                position: relative;
                                &::after {
                                    content: " ";
                                    position: absolute;
                                    top: 7px;
                                    bottom: 7px;
                                    right: 0;
                                    background-color: $grey-300;
                                    width: 1px;
                                }
                            }
                        }
                    }
                }
            }
            .lorrySelection {
                margin-top: 0px;
            }
        }
        .body {
            @extend .mobilePaddingContainer;
            .shortDesc {
                // margin-top: 10px;
            }

            .listItems {
            }
            .miscContent {
                margin: 20px -20px;
            }
        }
        .noBackground {
            margin-top: 16px;
        }
        .noPaddedBody {
            padding: 0px;
        }
    }
}
