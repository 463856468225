@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.main {
    .icon {
        overflow: hidden;
    }
    .ctaContainer {
        width: 100%;
        display: flex;
        gap: 20px;
        justify-content: flex-end;
    }
    .header {
        font-size: calcRem(20);
        line-height: calcRem(30);
        font-family: var(--font-medium);
    }
    .description {
        font-size: calcRem(14);
        line-height: calcRem(21);
        color: $grey-500;
        & > {
            text-align: center;
        }
    }
}
