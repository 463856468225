@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.main {
    max-width: 560px;
    h2 {
        color: #000;
        font-family: var(--font-semiBold);
        text-align: center;
    }
    p {
        color: #000;
        text-align: center;
        font-family: var(--font-semiBold);
    }
    button {
        margin: 10px auto;
    }
}

.imageBox {
    // width: 250px;
    // height: 210px;
    border-radius: 15px;
    margin: 0 auto 25px;
    overflow: hidden;
    img {
        width: 100%;
        height: auto;
    }
}

@media (max-width: $mobileBreakpointMaxWidth) {
    .main {
        margin: 50px auto;
        padding: 0 20px;
        h2 {
            font-size: calcRem(18);
        }
        p {
            font-size: calcRem(12);
        }
    }
}

@media (min-width: $desktopBreakpointMinWidth) {
    .main {
        margin: 20px auto;
        padding: 0;
        h2 {
            font-size: calcRem(24);
        }
        p {
            font-size: calcRem(14);
        }
    }
}
