@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.mainWrapper {
    display: flex;
    flex-direction: column;
}
@media (min-width: $desktopBreakpointMinWidth) {
    .mainWrapper {
        gap: 50px;
        padding: 0 20px;
        .inputWrapper {
            margin: 50px 0;
        }
    }
}

@media (max-width: $mobileBreakpointMaxWidth) {
    .mainWrapper {
        gap: 20px;
        .inputWrapper {
            margin: 30px 0;
        }
    }
}
