@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";
@import "@vahak/core/dist/scss/mixins";

.main {
    display: flex;
    gap: 20px;
    align-items: center;

    [data-one-line-text] {
        @include textEllipsis(1);
    }
}
