@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

$mkt-lorry-space: 16px;

.adminOptionDropdown {
    left: -140px;
    button {
        justify-content: flex-start;
        margin: 0;
        padding: 8px;
        &:not(:last-of-type) {
            border-radius: 0;
            border-bottom: 1px solid #ccc;
        }
    }
}

.main {
    width: 343px;
    border-radius: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

    // height: 343px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .lorryWrapper {
        padding-top: $mkt-lorry-space;
        padding-right: $mkt-lorry-space;
        display: grid;
        grid-template-columns: auto max-content;
        column-gap: 12px;
    }
    .bodyContent {
        padding: 12px $mkt-lorry-space;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .lorryOtherInfo {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
        }
        .divider {
            border: 1px solid $grey-200;
            height: 60px;
        }

        .callBtn {
            margin-left: auto;
        }

        .trackBtn {
            margin-left: auto;
            button {
                height: 40px;
            }
        }
    }

    .footerContent {
        background-color: #ededed;
        padding: 14px 12px;
        display: grid;
        grid-template-columns: 1fr max-content;
        align-items: center;
        column-gap: 8px;

        .bidBtn {
            button {
                height: 40px;
            }
        }
    }
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .main {
        &.mobileResponsive {
            width: 100%;
        }
    }
}
