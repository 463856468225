@import "@vahak/core/dist/scss/functions";
.MainContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.iconContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.MainContainer__web {
    margin-left: 43px;
    margin-right: 43px;
}

.MainContainer__mob {
    margin-left: 28px;
    margin-right: 28px;
}

.ButtonStyle__web {
    margin-top: 50px;
    margin-bottom: 24px;
}
.ButtonStyle__mob {
    margin-top: 30px;
    margin-bottom: 24px;
}

.cancelLimit_mob {
    width: 218px;
    height: 164px;
}

.cancelLimit_web {
    width: 416px;
    height: 312px;
}

.CancelHeading {
    text-align: center;
    color: #010a10;
    opacity: 1;
}
.CancelHeading__mob {
    font-size: #{calcRem(16)};
    line-height: 23px;
    letter-spacing: #{calcRem(-0.36)};
    margin-top: 15px;
    font-weight: bold;
}

.CancelHeading__web {
    font-size: #{calcRem(32)};
    line-height: 41px;
    letter-spacing: #{calcRem(-0.64)};
    margin-top: 15px;
    font-weight: bold;
    max-width: 448px;
    margin-left: auto;
    margin-right: auto;
}

.icon__web {
    border: none;
    margin-top: 15px;
    margin-right: 20px;
}
.icon__mob {
    border: none;
    margin-right: 5px;
    // margin-top:15px;
    // margin-right: 20px;
}

.confirmationHeading {
    color: #fd0d1b;
    opacity: 1;
    text-decoration-line: underline;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    p {
        color: #fd0d1b;
    }
}
.confirmationHeading__mob {
    font-size: #{calcRem(14)};
    line-height: 18px;
    letter-spacing: #{calcRem(0)};
    margin-bottom: 32px;
    margin-top: 18px;
}

.confirmationHeading__web {
    font-size: #{calcRem(14)};
    line-height: 18px;
    letter-spacing: #{calcRem(0)};
    margin-bottom: 50px;
    p {
        font-size: #{calcRem(14)};
        line-height: 18px;
        letter-spacing: #{calcRem(0)};
        margin: 0;
        cursor: pointer;
    }
}
