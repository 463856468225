@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.toggleButton {
    text-decoration: none;
}
@media (min-width: $desktopBreakpointMinWidth) {
    .callbackRequestHeader {
        h5 {
            font-size: 24px;
            font-family: var(--font-semiBold);
        }
    }
}

@media (max-width: $mobileBreakpointMaxWidth) {
    .toggleButton {
        padding: 0 !important;
    }
    .bottomSheetHeading {
        font-size: calcRem(18);
        padding-bottom: 10px;
    }
}
