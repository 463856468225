@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

//Desktop Screen only

.main {
    display: flex;
    align-items: center;
    background-color: $grey-200;
    border-radius: 60px;
    padding: 10px 30px;
    font-size: calcRem(16);
    gap: 12px;
    .info {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 2px;
    }
    .action {
        path {
            stroke: $grey-500;
        }
    }

    .limit {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .routes {
        display: inline-flex;
        align-items: center;
        gap: 8px;
        color: $grey-900;
        font-weight: 600;
        .route {
            white-space: nowrap;
            &:nth-last-child(1) {
                @extend .limit;
            }
        }
    }
    .bullets {
        font-weight: 600;
        font-size: calcRem(14);
        color: $grey-500;
        @extend .limit;
    }
    .desc {
        @extend .bullets;
        font-size: calcRem(13);
    }
    .availability {
        @extend .limit;

        font-size: calcRem(12);
        line-height: calcRem(18);
        font-weight: 500;
        color: $green-700;
        display: inline-flex;
        align-items: center;
        gap: 5px;

        svg path {
            height: 14px;
            width: 14px;
            fill: $green-700;
        }
    }
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .main {
        font-size: calcRem(13);
        line-height: calcRem(18);

        .bullets {
            font-size: calcRem(14);
        }
        .availability {
            font-size: calcRem(14);
        }
        .desc {
            font-size: calcRem(11);
        }
    }
}
