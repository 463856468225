@import "@vahak/core/dist/scss/variables";
@import "@vahak/core/dist/scss/functions";

.main {
    width: 343px;
    border-radius: 16px;
    box-shadow: 0 2px 5px -1px rgba(50, 50, 93, 0.25), 0 1px 3px -1px rgba(0, 0, 0, 0.3);
    padding: 25px 38px;
    justify-content: center !important;
    align-items: center;
    background-image: url("https://vahak-tr.s3.ap-south-1.amazonaws.com/nudge-banner/nudge-card-pattern.svg"),
        linear-gradient(#1f41b4, #122f93);
    background-size: cover;
    background-position: center;

    .textWrapper {
        width: 160px;
        text-align: center;
    }
}

//Desktop Screen only
@media (min-width: $desktopBreakpoint) {
}

// Mid screen
@media (min-width: $mobileBreakpoint) and (max-width: $desktopBreakpoint) {
}

//Small Screen only
@media (max-width: $mobileBreakpoint) {
    .main {
        width: 100%;
        padding: 26px 20px;
        padding-right: 0;
        justify-content: space-between !important;
        align-items: center;
        text-align: left;

        &.load {
            padding-right: 40px;
        }

        &.lorry {
            img {
                margin-right: -40px !important;
                width: 206px !important;
                height: 64px !important;
                object-fit: cover;
            }
        }

        .textWrapper {
            width: 150px;
            text-align: left;
        }

        button {
            height: 34px;
        }
    }
}
